<template>
  <q-page-sticky
    position="bottom-left"
    :offset="[16, 16]"
    :class="[{ avtInner: $route.name != 'Home' }, 'ageVerifyPopover']"
  >
    <q-btn
      round
      color="primary"
      size="lg"
      @click="toggleEAIVDialog()"
      v-show="showVerificationBtn"
    >
      <SvgIcon size="3rem" icon="age-verify" />
    </q-btn>
    <div
      class="ageVerifyPopoverInfo text-center"
      :class="showAgeVerifyPopover ? 'scaleIn' : 'scaleOut'"
    >
      <q-btn
        unelevated
        round
        color="grey-5"
        size="sm"
        icon="close"
        @click="toggleEAIVDialog()"
        class="absolute-top-right q-mt-md q-mr-md"
      />
      <AgeVerificationInfo :toggleDialog="toggleEAIVDialog" />
    </div>
  </q-page-sticky>
</template>

<script>
import AgeVerificationInfo from 'components/dialog/eaiv/AgeVerificationInfo'
import { ageRestrictedBrowsingPages } from 'src/constants'

export default {
  name: 'AgeVerificationBtn',
  components: {
    AgeVerificationInfo,
  },
  data() {
    return {
      dialog: 'showAgeVerifyPopover',
      ageRestrictedBrowsingPages: ageRestrictedBrowsingPages,
    }
  },
  computed: {
    showAgeVerifyPopover: {
      get() {
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val: val,
        })
      },
    },
    showVerificationBtn() {
      let isVisible = this.ageRestrictedBrowsingPages.includes(this.$route.name)
      if (!isVisible && this.showAgeVerifyPopover) {
        this.toggleEAIVDialog()
      }
      return this.ageRestrictedBrowsingPages.includes(this.$route.name)
    },
  },
  methods: {
    toggleEAIVDialog() {
      this.showAgeVerifyPopover = !this.showAgeVerifyPopover
    },
  },
}
</script>
